import * as React from "react";
import HomePage from "./home-page";
import { SEO } from "../components/seo";
import { descriptions } from "../libs/seoContent";

const Index = () => {
  return <HomePage />;
};

export default Index;

export const Head = () => {
  return (
    <>
      <SEO title="Home" description={descriptions.home} />
      <meta name="facebook-domain-verification" content="orwznfak8neicir2b4qg2pbs0ngcti" />
    </>
  );
};
