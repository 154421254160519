export const descriptions = {
  home: "Nando's. It's a culture. There are a range of jobs from restaurant Team Member jobs, Restaurant management jobs, Head Office and Early Careers. We’re here to help you become the very best person you can be at Nando's. Everyone is Welcome. Apply now.",
  roles:
    "Restaurant jobs, Head Office (or Central Support) jobs and Early Careers and more are available at Nando's. There is always something to be a part of. Respectful and inclusive culture is a key business priority for us, one that is imperative to our success. Find out more about the different roles we offer now. Find the right fit for you. Search for jobs. Apply for one of our jobs now.",
  teamMember:
    "Create an unforgettable Nando’s experience for our customers. From Hosts and Grillers to Cashiers and Co-ordinators, find your fit with our part-time and full-time Front-of-House and Back-of-House roles. No experience needed. Find out more. Search for jobs. Apply now.",
  restaurantManager:
    "We’re all about progression at Nando’s. That’s why we have a few different managerial roles – from Assistant Managers and General Restaurant Managers (or Patrao’s) to Regional Managers and Regional Directors. Find the right job for you. Search for jobs. Apply now.",
  support:
    "There’s no talk of Head Office, we go by Central Support. Why? It’s a hub at the centre of all our restaurants, designed to help them be the best they can be. Made up of Finance, Product, Customer, Property, People and Technology teams. Find out more. Search for jobs. Apply now.",
  earlyCareers:
    "Join Nando’s Varsity today. Nando’s Varsity is our apprenticeship scheme. It’s all about coaching and training Team Members who are looking to achieve a fully funded diploma in Hospitality. We’re currently recruiting for our Level 2 Programme. Find out more. Search for jobs. Apply now.",
  everyoneIsWelcome:
    "With people at our core, we actively address any barriers our current and future teams face inside and outside Nando’s. We take responsibility and accountability of the role we play and need to continue to play in creating a fairer and equal society, for everyone.",
  whyNandos:
    "Why Nando's? We’ve won awards for being a great place to work. We want to inspire you to be the best you can be. Above all, it’s the people that make the chicken and so it’s important we make Nando’s an inclusive, fun place to work with plenty of opportunities to grow. From time management and problem solving, to building resilience and developing relationships, we’re here to help you become the best you can be. Find out more about Nando's.",
};
